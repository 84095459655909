import { action, observable, computed } from 'mobx';
import moment from 'moment';
import { isEqual, groupBy } from 'lodash';
import { AWS_CRITICAL_APIS, CLOUD_PROVIDERS } from 'app/util/constants';
import { parseQueryString } from 'app/util/utils';
import api from 'core/util/api';
import CloudExportTask from 'app/stores/clouds/CloudExportTask';
import { showSuccessToast, showErrorToast } from 'core/components';
import Model from 'core/model/Model';
import { isGoogleCloud } from '@kentik/ui-shared/util/map';
import CloudExportTaskCollection from './CloudExportTaskCollection';
import CloudConnectivityReportCollection from './CloudConnectivityReportCollection';

class CloudStore {
  collection = new CloudExportTaskCollection();

  connectivityReportCollection = new CloudConnectivityReportCollection();

  @observable
  statsFetched = false;

  @observable
  isAwsValid = null;

  @observable
  isAwsCheckLoading = false;

  initialize() {
    // Don't need to block on this
    this.collection.fetch();
  }

  @computed
  get hasClouds() {
    return this.collection.unfilteredSize > 0;
  }

  /*
    Assembles a breakdown of number of cloud exports by cloud provider

    ex:

    {
      aws: 13,
      azure: 7
    }
  */
  @computed
  get cloudExportCount() {
    return this.collection.reduce((breakdown, model) => {
      let provider = model.get('cloud_provider');

      if (isGoogleCloud(provider)) {
        provider = 'gcp';
      }

      return {
        ...breakdown,
        [provider]: (breakdown[provider] || 0) + 1
      };
    }, {});
  }

  /*
    Given a cloud provider, returns a boolean indicating whether that provider has 1 or more cloud exports
  */
  hasCloudExports(cloudProvider) {
    return this.cloudExportCount[cloudProvider] > 0;
  }

  /*
    returns a list of unique, sorted cloud providers that support config status checks

    for each of the hard-coded providers that we know has support, we must also have:

    1. exports for the given provider
    2. a topology collection defined for the given provider
  */
  @computed
  get configStatusCloudProviders() {
    const { $hybridMap } = this.store;

    return ['aws', 'azure', 'gcp', 'oci']
      .filter((provider) => this.hasCloudExports(provider) && $hybridMap[`${provider}CloudMapCollection`])
      .sort();
  }

  @action
  loadClouds(cloud_export_id) {
    this.collection.statsFetched = false;
    this.statsFetched = false;

    return this.collection.fetch().then(() => {
      if (this.collection.size) {
        return this.loadCloudStatus(this.collection, cloud_export_id).then(() => {
          this.collection.statsFetched = true;
          this.statsFetched = true;
        });
      }
      this.collection.statsFetched = true;
      this.statsFetched = true;
      return undefined;
    });
  }

  loadCloudStatus(collection, cloud_export_id) {
    const options = {};
    if (cloud_export_id) {
      options.query = { cloud_export_id };
    }

    return api.get('/api/ui/devices/cloud-status/', options).then((stats) => {
      if (cloud_export_id) {
        const cloud = collection.get(cloud_export_id);
        if (cloud) {
          this.processCloudStatus(cloud, stats);
        }
      } else {
        collection.each((cloud) => this.processCloudStatus(cloud, stats));
      }
    });
  }

  loadCloudPaths(sourceId, destId) {
    // cloudConnectivityReport/paths/vpc-762f900d/10.255.18.18
    return api.get(`/api/ui/cloudConnectivityReport/paths/${sourceId}/${destId}`);
  }

  buildConnectivityReport(reportModel) {
    return api.post('/api/ui/cloudConnectivityReport/buildReport', { data: reportModel.toJS() });
  }

  @action
  processCloudStatus(cloud, deviceStatusMap) {
    // get the devices for the given cloud model and tack on the matching stats
    const devices = cloud.get('devices').map((device) => ({
      ...device,
      ...deviceStatusMap[device.id]
    }));

    const downSampledFps = devices.reduce((acc, device) => acc + device.downsampled_fps, 0);
    const rawFps = devices.reduce((acc, device) => acc + device.raw_fps, 0);

    cloud.set({ devices, downSampledFps, rawFps });

    // And now for a bunch of stuff unrelated to cloud statistics altogether!
    const properties = cloud.get('properties');
    if (
      cloud.get('cloud_provider') === 'azure' &&
      properties.azure_security_principal_enabled &&
      properties.azure_access_check &&
      !properties.azure_access_check.flow_found
    ) {
      return this.updateAzureCheck(cloud).then(() => this.updateTaskStatus(cloud));
    }
    return this.updateTaskStatus(cloud);
  }

  getAwsErrorCode(error) {
    // is glacier bucket
    if (error.includes('Glacier Bucket')) {
      return 'GlacierBuckerError';
    }

    // bucket is empty
    if (error.includes('is empty in bucket')) {
      return 'EmptyBuckerError';
    }

    const matcher = error.match(/^error: (.+):/);
    return (matcher && matcher[1]) || 'AccessDenied';
  }

  validateAwsRole(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/aws/role', { data, showErrorToast: false });
  }

  validateAwsRegion(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/aws/apis', { data, showErrorToast: false });
  }

  validateAwsBucket(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/aws/bucket', { data, showErrorToast: false });
  }

  validateAwsSecondaryRoles(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/aws/secondaryRoles', { data, showErrorToast: false });
  }

  validateOCIAccess(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/oci/role', { data, showErrorToast: false });
  }

  listOciChildrenCompartments(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/oci/listCompartments', { data, showErrorToast: false });
  }

  listAwsChildAccount(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/aws/listAccounts', { data, showErrorToast: false });
  }

  validateOCIBucketAccess(data) {
    return api.post('/api/ui/cloudExport/cloudCheck/oci/bucket', { data, showErrorToast: false });
  }

  @action
  checkAws(data, requestOptions = {}) {
    this.isAwsValid = null;
    this.isAwsCheckLoading = true;

    return api
      .post('/api/ui/cloudExport/cloudCheck/aws', {
        data,
        showErrorToast: false,
        rawResponse: true,
        ...requestOptions
      })
      .then(
        (response) => {
          this.isAwsValid = response.body.success;
          this.isAwsCheckLoading = false;
          return { success: response.body.success };
        },
        (response) => {
          const { error } = response.body;
          this.isAwsValid = false;
          this.isAwsCheckLoading = false;
          return {
            success: false,
            errorCode: this.getAwsErrorCode(error),
            error,
            response
          };
        }
      );
  }

  /*
    where data is:

    {
      subscription_id: <properties.azure_subscription_id>,
      resource_group: <properties.azure_resource_group>,
      location: <properties.azure_location>,
      flow_enabled: <properties.azure_collect_flow_logs>,
      storage_account: <properties.azure_storage_account>,
      skip_metadata_collection: <properties.skip_metadata_collection>
    }

    and returns:

    {
      error_msg: <string>,
      api_access: <boolean>,
      flow_found: <boolean>,
      storage_account_access: <boolean>
    }
  */
  @action
  checkAzure(data) {
    if (!data.flow_enabled || data.flow_enabled === 'false') {
      data.storage_account = 'kentik-no-flow';
    }
    return api.post('/api/ui/cloudExport/cloudCheck/azure', { data }).then((response) => response.access_check);
  }

  checkAzureStorageAccounts() {
    return api.get('/api/ui/cloudExport/cloudCheck/azure/storage');
  }

  // checks aws bucket or azure subscription id(s) or gcp project id to ensure they are unique across kentik
  checkCloudProviderIdEligibility({ cloudProvider, ids = [] }) {
    return api.post('/api/ui/cloudExport/eligibility', { data: { cloudProvider, ids } }).then((eligibilityResponse) => {
      if (eligibilityResponse.success === false) {
        throw new Error(eligibilityResponse.message);
      }
    });
  }

  @action
  checkGcp(data, requestOptions = {}) {
    this.isGcpValid = null;
    this.isGcpCheckLoading = true;

    return api
      .post('/api/ui/cloudExport/cloudCheck/gcp', {
        data,
        showErrorToast: false,
        rawResponse: true,
        ...requestOptions
      })
      .then(
        (response) => {
          this.isGcpValid = response.body.success;
          this.isGcpCheckLoading = false;
          return { success: response.body.success };
        },
        (response) => {
          const { error } = response.body;
          this.isGcpValid = false;
          this.isGcpCheckLoading = false;
          return {
            success: false,
            errorCode: this.getGcpErrorCode(error),
            error,
            response
          };
        }
      );
  }

  @action
  updateTaskStatus(cloud) {
    const properties = cloud.get('properties');
    const { task_status: taskStatus, error_message: errorMessage } = properties;

    if (cloud.get('enabled') && taskStatus !== 'OK') {
      let newTaskStatus = taskStatus;
      let newErrorMessage = errorMessage;
      if (cloud.get('downSampledFps') || cloud.get('rawFps')) {
        newTaskStatus = 'OK';
        newErrorMessage = '';
      } else if (taskStatus === 'PENDING' && properties.azure_collect_flow_logs === false) {
        newTaskStatus = 'OK';
        newErrorMessage = '';
      } else if (
        taskStatus !== 'ERROR' &&
        taskStatus !== 'HALT' &&
        moment().isAfter(moment(cloud.get('edate')).add(30, 'minutes'))
      ) {
        newTaskStatus = 'ERROR';
        newErrorMessage = 'Export Process timed out';
      } else if (taskStatus !== 'ERROR' && taskStatus !== 'START' && taskStatus !== 'HALT') {
        newTaskStatus = 'PENDING';
        newErrorMessage = '';
      }

      if (taskStatus !== newTaskStatus) {
        cloud.save(
          { properties: { ...properties, task_status: newTaskStatus, error_message: newErrorMessage } },
          { patch: true, toast: false }
        );
      }
    }
  }

  @action
  updateAzureCheck(cloud) {
    const properties = cloud.get('properties');

    return this.checkAzure({
      subscription_id: properties.azure_subscription_id,
      resource_group: properties.azure_resource_group,
      location: properties.azure_location,
      storage_account: properties.azure_storage_account,
      skip_metadata_collection: properties.skip_metadata_collection
    }).then((azure_access_check) => {
      // if we're skipping metadata collection for this cloud export, azure_access_check.api_access will be false
      // but we still want cloud export enabled so that transflou will get flow from storage account (assuming we have access to it)
      const okToEnable = properties.skip_metadata_collection
        ? properties.skip_metadata_collection
        : azure_access_check.api_access;
      const enabled = azure_access_check.storage_account_access && okToEnable;

      if (enabled !== cloud.get('enabled') || !isEqual(azure_access_check, properties.azure_access_check)) {
        return cloud.save({ enabled, properties: { ...properties, azure_access_check } }, { patch: true });
      }

      return null;
    });
  }

  @computed
  get hasFlow() {
    return this.collection.some((cloudExport) => cloudExport.get('rawFps') > 0);
  }

  @computed
  get cloudProviderChartData() {
    return this.collection.models.reduce((acc, cloudExport) => {
      if (cloudExport.get('downSampledFps')) {
        acc.push({
          name: cloudExport.get('name'),
          model: cloudExport,
          data: [
            {
              y: Math.ceil(cloudExport.get('downSampledFps')),
              name: CLOUD_PROVIDERS[cloudExport.get('cloud_provider').toUpperCase()].code
            }
          ]
        });
      }
      return acc;
    }, []);
  }

  navigateToCloud = (cloudId) => {
    this.history.push(`/v4/assets/clouds/${cloudId}`);
  };

  // this is deprecated now that cloud export wizard creates azure cloud exports
  // TODO: remove once we've verified it's not used anywhere else
  createAzureCloudFromParams(urlParams) {
    const { params, success, errorMsg } = parseQueryString(urlParams);
    const isSuccessful = success === 'true';

    if (!isSuccessful) {
      showErrorToast(`Error enabling Azure Security Principal: ${errorMsg}`);
    }
    const defaultsStub = new CloudExportTask().get('properties');
    const cloudAttributes = JSON.parse(params);

    cloudAttributes.properties = {
      ...defaultsStub,
      ...cloudAttributes.properties,
      azure_security_principal_enabled: isSuccessful
    };
    return this.collection.forge(cloudAttributes);
  }

  /*
    returns a list of supported region names keyed by cloud provider
  */
  getCloudRegions(clouds = []) {
    if (Array.isArray(clouds)) {
      const { $dictionary } = this.store;

      return clouds.reduce(
        (acc, cloud) => ({
          ...acc,
          [cloud]: Object.keys($dictionary.get(`cloudMetadata.${cloud}.regions`, {})).sort()
        }),
        {}
      );
    }

    return {};
  }

  @action
  async getCloudConfigStatus({ cloudProvider, force = false }) {
    const { $hybridMap } = this.store;
    const topologyCollection = $hybridMap[`${cloudProvider}CloudMapCollection`];

    if (!topologyCollection) {
      console.warn(
        `Cloud provider '${cloudProvider} does not have a topology collection. Canceling config status check.`
      );
      return { statusData: [], noFlowLogs: [] };
    }

    const cloudConfigPromiseKey = `cloudConfigStatus_${cloudProvider}Promise`;

    if (force === true) {
      this[cloudConfigPromiseKey] = null;
    }

    if (this[cloudConfigPromiseKey]) {
      return this[cloudConfigPromiseKey];
    }

    this[cloudConfigPromiseKey] = Promise.all([
      topologyCollection.fetch(),
      api.post(`/api/ui/cloudExport/cloudCheck/${cloudProvider}/apis`, {
        data: { all: true, force },
        showErrorToast: false
      }),
      api.get('/api/ui/devices/cloud-status/')
    ]).then(
      (res) => {
        const [topo, apiRes, deviceStats] = res;
        const { Entities } = topo;
        const Vpcs = topologyCollection.getModelsByEntityType('Vpcs');
        const FlowLogs = Entities?.FlowLogs || {};
        const statusData = [];

        // aggregate the fps by cloud export id
        // we'll use this in the flow column renderer to determine success
        const rawFpsByExportId = Object.values(deviceStats).reduce((acc, device) => {
          const { cloud_export_id, raw_fps } = device;
          const exportStats = acc[cloud_export_id] || 0;

          return {
            ...acc,
            [cloud_export_id]: exportStats + raw_fps
          };
        }, {});

        // Merge by account id
        const mergedApiRes = {};

        apiRes.forEach((cloudExport) => {
          const { id, subscriptionId, role, region, results, name, success, properties, FetchDate, cloud_provider } =
            cloudExport;

          if ((subscriptionId || role) && region) {
            const accountId = subscriptionId || (role.match(/(?::)([0-9]+)(?=:)/) || ['', '---'])[1];
            const account = mergedApiRes[`${accountId}:${region}`];
            const exportModel = this.collection.get(id) || new Model({ id, notFound: cloudExport });
            const rawFps = rawFpsByExportId[id] || 0;

            if (!account) {
              mergedApiRes[`${accountId}:${region}`] = {
                results,
                region,
                accountId,
                success,
                exports: [exportModel],
                rawFps,
                FetchDate
              };
            } else {
              account.exports.push(exportModel);
              account.rawFps += rawFps;
            }
          }

          if (cloud_provider && isGoogleCloud(cloud_provider)) {
            const exportModel = this.collection.get(id) || new Model({ id, notFound: cloudExport });
            mergedApiRes[`${properties.gce_project}:${name}`] = {
              results,
              region: properties.gce_project,
              accountId: properties.gce_subscription,
              success,
              FetchDate,
              exports: [exportModel],
              rawFps: rawFpsByExportId[id] || 0
            };
          }

          if (cloud_provider === 'oci') {
            const exportModel = this.collection.get(id) || new Model({ id, notFound: cloudExport });

            const groupedByRegion = groupBy(results, (result) => result?.region ?? properties.oci_default_region);
            const { compartmentId } = cloudExport;

            Object.keys(groupedByRegion).forEach((regionName) => {
              const everyApiCallSuccessful = !!groupedByRegion[regionName]?.every((apiCall) => apiCall.success);

              mergedApiRes[`${name}:${compartmentId}:${regionName}`] = {
                results: groupedByRegion[regionName],
                region: regionName,
                accountId: compartmentId,
                FetchDate,
                success: everyApiCallSuccessful,
                exports: [exportModel],
                rawFps: rawFpsByExportId[id] || 0
              };
            });
          }
        });

        // Parse Api data
        Object.values(mergedApiRes).forEach((acct) => {
          const { results, success, ...rest } = acct;
          const hasExportErrors = acct.exports.some(
            (cloudExport) => cloudExport.get('enabled') && cloudExport.get('properties.task_status') === 'ERROR'
          );
          const status = {
            apiStatus: 'success',
            apiMsg: '',
            flowStatus: 'none',
            flowMsg: '',
            apiData: {},
            flowData: [],
            exportStatus: 'success',
            exportMsg: '',
            ...rest
          };

          if (hasExportErrors) {
            status.exportStatus = 'error';
            status.exportMsg = 'At least one export has an error';
          }

          status.apiData = results.map((acctApi) => {
            const { service, fn, success: apiSuccess, message } = acctApi;
            const isSuccess = apiSuccess === true;
            const endpoint = `${service}:${fn}`;
            let errMsg = isSuccess ? undefined : `Received Error when attempting to access endpoint: ${message}`;
            let resultedSuccess = apiSuccess === true ? 'success' : 'Error';
            if (apiSuccess === 'warning') {
              errMsg = message;
              resultedSuccess = apiSuccess;
            }

            if (!apiSuccess && AWS_CRITICAL_APIS.includes(endpoint)) {
              status.apiStatus = 'Critical';
              status.apiMsg = 'Critical API Endpoint missing';
            }

            return {
              service,
              fn,
              endpoint,
              errMsg,
              success: resultedSuccess
            };
          });

          if (!status.apiMsg && !success) {
            // determine if missing some or all endpoints
            const noAccess = results
              // exclude warnings from counts
              .filter((endpoint) => endpoint.success !== 'warning')
              .every((endpoint) => !endpoint.success);
            status.apiStatus = noAccess ? 'Error' : 'Warning';
            status.apiMsg = `Unable to access ${noAccess ? 'all' : 'some'} API Endpoints`;
          }

          return statusData.push(status);
        });

        const noFlowLogs = Object.values(FlowLogs).length === 0;

        // Parse Flow data
        Vpcs.forEach((vpc) => {
          const logs = Object.values(FlowLogs).filter((flow) => flow.ResourceId === vpc.id);
          const s3Logs = logs.filter((log) => log.LogDestinationType === 's3');
          const acct = statusData.find((exp) => exp.accountId === vpc.OwnerId && exp.region === vpc.RegionName);

          if (acct) {
            if (s3Logs.length !== 0 && acct.flowStatus === 'none') {
              acct.flowStatus = 'success';
            } else if (s3Logs.length === 0 && !acct.flowMsg && !noFlowLogs) {
              acct.flowStatus = 'Error';
              acct.flowMsg = 'Missing flow for VPC(s)';
            }

            const flowObj = {
              entityId: vpc.id,
              flowLog: logs,
              entity: vpc
            };

            if (s3Logs.length !== 0) {
              flowObj.success = 'success';
              flowObj.bucket = logs.map((log) => ({ dest: log.LogDestination, id: log.FlowLogId }));
              flowObj.exportId = acct.exports.find(
                (ex) => !!s3Logs.find((log) => log.LogDestination.includes(ex.get('properties.aws_bucket')))
              )?.id;
            } else if (logs.length > 0) {
              logs
                .filter((log) => log.LogDestinationType !== 's3')
                .forEach((log) => {
                  acct.flowStatus = 'Error';
                  acct.flowMsg = 'Missing flow for VPC(s)';
                  flowObj.success = 'error';
                  flowObj.bucket = `Log dest: ${log.LogDestinationType} not accessible by Kentik`;
                  flowObj.errMsg = 'Flow Log not found for this VPC';
                });
            } else {
              flowObj.success = noFlowLogs ? 'noFlow' : 'error';
              flowObj.bucket = 'Flow logs not configured';
              flowObj.errMsg = noFlowLogs ? 'No flow logs found in AWS metdata' : 'Flow Log not found for this VPC';
            }

            acct.flowData.push(flowObj);
          }
        });

        return { statusData, noFlowLogs };
      },
      () => {
        const cloudProviderLabel = CLOUD_PROVIDERS.byId(cloudProvider).code || 'cloud';
        showErrorToast(`Error occurred attempting to fetch the ${cloudProviderLabel} config status results`);
        return { statusData: [], noFlowLogs: [] };
      }
    );

    return this[cloudConfigPromiseKey];
  }

  async bulkSaveTests(tests) {
    return api
      .post('/api/ui/synthetics/bulk-save', { data: { tests } })
      .then(() => showSuccessToast('Results will be available in 5 minutes', { title: 'Tests updated successfully' }));
  }

  /*
    Given a list of subscription ids, checks the Azure API to ensure they are valid and exist
  */
  async checkAzureSubscriptionIds(subscriptionIds, location) {
    return api.post('/api/ui/cloudExport/onboarding/azure/subscriptions', {
      data: { subscriptionIds, location },
      showErrorToast: false
    });
  }

  /*
    Given a subscription id, returns a list of resource groups
  */
  async getAzureResourceGroups(subscriptionId) {
    return api.get(`/api/ui/cloudExport/onboarding/azure/${subscriptionId}/resourceGroups`, { showErrorToast: false });
  }

  /*
    Executes a KQL query against the Azure resource graph

    example query that returns all unique entity types in company's tenant: 'Resources | distinct type'
  */
  async executeAzureResourceGraphQuery({
    query,
    isSingleTenant = false,
    subscriptionIds = this.azureSubscriptionIds,
    ...rest
  }) {
    return api.post('/api/ui/topology/azure/resource-graph', {
      data: { subscriptionIds, query, isSingleTenant, ...rest }
    });
  }

  /**
   * will extract data from executeAzureResourceGraphQuery response
   */
  async fetchAzureResourceGraphQueryData({
    query,
    isSingleTenant = false,
    subscriptionIds = this.azureSubscriptionIds
  }) {
    return this.executeAzureResourceGraphQuery({ subscriptionIds, query, isSingleTenant }).then((response) => {
      if (isSingleTenant) {
        const { data, success } = response;

        if (success) {
          return data;
        }

        return [];
      }

      return Object.values(response).reduce((carry, tenantData) => {
        const { data, success } = tenantData;
        if (success) {
          carry = [...carry, ...data];
        }

        return carry;
      }, []);
    });
  }

  /** will extract azure subscription ids from exporters */
  @computed
  get azureSubscriptionIds() {
    const totalSubscriptionIds = this.collection.reduce((subscriptionIds, model) => {
      const provider = model.get('cloud_provider');

      if (provider === 'azure') {
        const subscription_id = model.get('properties.azure_subscription_id');
        const enrichmentScopes = model.get('properties.azure_enrichment_scope') ?? [];
        if (subscription_id) {
          subscriptionIds.push(subscription_id);
        }

        subscriptionIds.push(...enrichmentScopes.map(({ subscriptionId }) => subscriptionId));
      }

      return subscriptionIds;
    }, []);

    return [...new Set(totalSubscriptionIds)].sort();
  }
}

export default new CloudStore();
